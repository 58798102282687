$transparent: #00000000;
$fade-grey-2: #2b2b2b2f;
$fade-grey-3: #2b2b2b4c;
$fade-grey-4: #2b2b2ba1;
$fade-grey-6: #2b2b2b71;

$grey: #494f5a;
$light-grey: #aaaaaa;
$dark-grey: #282c34;

$offwhite: #eee;
$eggshell: #ddd;