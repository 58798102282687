.About {
  .teamListing {
    display: flex;
    width: 80vw;
    margin: 20px auto;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .TeamMember {
      display: inline-block;
      width: 300px;
      padding: 10px;
      background-color: $fade-grey-2;
      border-radius: 24px;
      box-shadow: 8px 5px 10px $fade-grey-3;
      color: $dark-grey;
      text-decoration: none;
      margin: 10px;

      &:hover {
        background-color: $fade-grey-4;

        h4, p {
          color: $eggshell;
        }
      }

      &:active {
        background-color: $fade-grey-6;
        margin-top: 20px;
        padding-bottom: 0;
        box-shadow: 8px 0 10px $fade-grey-3;
      }

      img {
        border: 2px solid $fade-grey-6;
        width: 200px;
        height: 200px;
        border-radius: 100%;
        box-shadow: 8px 5px 10px $fade-grey-3;
        margin: 10px 0;
      }
    }
  }
}