.Legal {
  max-width: 45vw;
  margin: 0 auto;

  blockquote {
    padding: 4px 10px;
    background-color: $eggshell;
    h4 {
      margin-bottom: 10px;
    }
  }
  p {
    text-align: left;
    margin: 0 0 12px;
  }
}