@import "./imports/mixins.scss";
@import "./imports/colors.scss";

html,body {
  overflow-x: hidden;
  background-color: $dark-grey;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 0.8em;
}

h3 {
  font-size: 1em;
  margin-top: 0px;
}

h4 {
  font-size: 0.8em;
  margin: 0;
}

h5 {
  font-size: 0.6em;
}

@include for-desktop-up {
  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.4em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    font-size: 1em;
  }
}

hr
{
  background-color: $fade-grey-6;
  border: none;
  height: 1px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.grid-center {
  align-self: center;
}

.leftAlign
{
  text-align: left;
}

.rightAlign
{
  text-align: right;
}

.faded {
  background-color: $fade-grey-3;
}

@import "./components/index.scss";
@import "./components/App.scss";
@import "./components/eventCallers.scss";
@import "./components/Nav.scss";
@import "./components/CustomOptions.scss";
@import "./components/CharacterSheet.scss";
@import "./components/Footer.scss";
@import "./components/InfoLinks.scss";
@import "./components/Legal.scss";
@import "./components/About.scss";