.App {
  text-align: center;

  .App-header {
    background-color: $dark-grey;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-top: 20px;

    .App-logo {
      height: 10vmin;
      pointer-events: none;
      @media (prefers-reduced-motion: no-preference) {
        animation: App-logo-spin infinite 3s cubic-bezier(0.63, -0.01, 0.2, 1);
      }
    }

    h1 {
      font-size: larger;
    }
  }

  .App-body {
    padding: 4px;
    background-color: white;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
